// These sub-routes should all visually wayfind as children of the "Support" tab
export const SUPPORT_SUB_ROUTES = {
  MESSAGING: '/messaging',
  LIVE_CHAT: '/live-chat',
  FORMS_LIBRARY: '/forms-library',
};

// Used to construct routes when we need to check the base of the route, not the full route
export const INTERNAL_BASE_ROUTES = {
  COORDINATION_OF_BENEFITS_BASE: '/highmark-cob',
};

export const APP_MANAGED_ROUTES = {
  ABOUT: '/about',
  ABOUT_BENEFICITY: '/about-beneficity',
  CARE_REDIRECT: '/get-care',
  COORDINATION_OF_BENEFITS: `${INTERNAL_BASE_ROUTES.COORDINATION_OF_BENEFITS_BASE}/NA`,
  ACCOUNT_MANAGEMENT: '/account-management',
  FORMS_LIBRARY: `/support${SUPPORT_SUB_ROUTES.FORMS_LIBRARY}`,
  ACCOUNT_MANAGEMENT_CONTACT_INFORMATION: `/account-management/contact-information`,
  ACCOUNT_MANAGEMENT_COMMUNICATION_PREFERENCE: `/account-management/communication-preferences`,
  GENERIC_MASONRY_PAGE: '/masonry/:version/:id',
  HOME: '/home',
  HSA_ENROLLMENT: '/hsaEnrollment',
  MEMBER_REDIRECT: '/member',
  MESSAGING: `/support${SUPPORT_SUB_ROUTES.MESSAGING}`,
  NEWSLETTER: '/newsletter',
  GLOSSARY: '/glossary',
  PRIVACY: '/privacy',
  LEGAL: '/legal',
  LEGAL_DETAILS_SUBPATH: '/legal-details',
  LEGAL_TERMS_OF_SERVICE: `/legal/legal-details?id=terms-of-service`,
  LEGAL_PRIVACY_POLICY: `/legal/legal-details?id=privacy-policy`,
  LIVE_CHAT: `/support${SUPPORT_SUB_ROUTES.LIVE_CHAT}`,
  SIGN_IN: '/sign-in',
  SIGN_IN_ALTERNATIVE: '/member/login', // This is a workaround needed to match mobile login path/deeplink, TODO: https://everlong.atlassian.net/browse/HMRK-7018
  SIGN_OUT: '/sign-out',
  START_LOGIN: '/start-login',
  SUPPORT: '/support',
  TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES:
    '/technical-assistance-and-all-languages',
  WALLET_REDIRECT: '/wallet',
  BACKGROUND_INFORMATION: '/background-information',
  PWP_SUBPATH: '/pwp',
  EASY_PLAN_FAQ: '/easy-plan-faq',
  EASY_PLAN_HOME: '/easy-plan-home',
  DOCUMENT_CENTER: '/documents',
};
